import {inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {StorageService} from './storage.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private coreApiUrl = environment.coreApiUrl;
  private isAuthenticated = false;
  private storageService: StorageService = inject(StorageService);
  private http: HttpClient = inject(HttpClient);
  private me: any = null;
  private router: Router = inject(Router);

  constructor() {
    const me: any = this.storageService.getItem('me');
    this.me = me;
    this.isAuthenticated = !!me?.accessToken;
    if (this.isAuthenticated) {
      this.updateUserInfo().subscribe({
        next: (res) => {
          this.me.user = res;
          this.setAuth(this.me);
        },
        error: () => {
          this.logout();
        }
      });
      this.updateUserInfo().subscribe((res) => {
        this.me.user = res;
        this.setAuth(this.me);
      });
    }
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post<any>(`${this.coreApiUrl}/auth/login`, { email, password });
  }

  register(clickup_user_email: string, clickup_user_api_key: string, password: string): Observable<any> {
    return this.http.post<any>(`${this.coreApiUrl}/auth/register`, { clickup_user_email, clickup_user_api_key, password });
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(`${this.coreApiUrl}/auth/forgot-password`, { email });
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http.post<any>(`${this.coreApiUrl}/auth/reset-password`, { token, password });
  }

  updateUserInfo() {
    return this.http.get<any>(`${this.coreApiUrl}/staffs/me`, {
      headers: {
        'Authorization': `Bearer ${this.me?.accessToken}`,
        'x-user-id': this?.me?.user.id
      }
    });
  }

  getUserRole() {
    const me: any = this.storageService.getItem('me');
    return me.user.role
  }

  getUserName() {
    const me: any = this.storageService.getItem('me');
    return me.user.full_name
  }

  getUserEmail() {
    const me: any = this.storageService.getItem('me');
    return me.user.email
  }

  setAuth(response: any): void {
    this.storageService.setItem('me', response);
    this.isAuthenticated = true;
  }

  logout(): void {
    this.storageService.removeItem('me');
    this.isAuthenticated = false;
    this.router.navigateByUrl('/login');
  }

  isLoggedIn(): boolean {
    return this.isAuthenticated;
  }
}
